import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/vercel/path0/node_modules/gatsby-theme-carbon/src/templates/Default.js";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <p><em parentName="p">{`Metcon Week`}</em></p>
    <p><strong parentName="p"><em parentName="strong">{`*`}{`We will be shooting a video during today’s noon class so if you’d
like to be in it show up!  No open gym during the shoot, from 11-1:30
please.`}</em></strong></p>
    <p><strong parentName="p"><em parentName="strong">{`“Adrienne”`}</em></strong></p>
    <p>{`400M Run`}</p>
    <p>{`34-Power Cleans (135/95)`}</p>
    <p>{`34-K2E’s`}</p>
    <p>{`34-Box Jumps (24/20)`}</p>
    <p>{`34-Power Snatch (95/65)`}</p>
    <p>{`34-KBS (53/35)`}</p>
    <p>{`34-Wall Balls (20/14)`}</p>
    <p>{`34-Bench Press (135/95)`}</p>
    <p>{`34-Pullups`}</p>
    <p>{`34-Burpees`}</p>
    <p>{`400M Run`}</p>
    <p>{`For time.`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      